const API_URL = "/api/simplecrm/entitytypes/"

class entityTypes {    
    static get = async (accessToken, typeId, getCache, setCache) => {
        // console.log ("------------")        

        // console.log ("getEntityType:"+typeId)

        if (getCache && getCache(typeId) !== undefined) {            
            // console.log ("cached")
            return getCache(typeId)
        }
            
        

        const fetchGet = await fetch(`${API_URL}${typeId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })     
                        
        if (!fetchGet.ok)
            throw new Error((await fetchGet.json()).error.code)

        
        const result = await fetchGet.json()
        
        if (setCache) {            
            setCache(typeId, result, 10)
        }

        // console.log ("not cached")
        // console.log ("------------")
        return result
    }

    static list = async (accessToken) => {
        const fetchGet = await fetch(`${API_URL}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })     
        
        if (!fetchGet.ok)
            throw new Error((await fetchGet.json()).error.code)
                
        return await fetchGet.json()
    }
}

export default entityTypes