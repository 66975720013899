import React, { useState, useEffect, Fragment } from 'react'

import {
    SimpleUIAuthState,
    SimpleUICommonReactInitializing,
    SimpleUIUseCache
} from './../../../../../../../simpleUI'

import { API } from './../../../../../../../components/common'
import { useRenderState } from './../../../../../../../components/context/renderStateProvider'
import Item from './item'

const Component = (props) => {    
    const { fieldId } = props    

    const { accessToken } = SimpleUIAuthState()    
    const { renderState, setRenderState, onChange, subscribe } = useRenderState()
    const { getCache, setCache, deleteCache } = SimpleUIUseCache()

    const [ layout, setLayout ] = useState()
    const [ list, setList ] = useState(undefined)
    
    useEffect(() => {
        const asyncFunction = async (data) => {            
            const entities = []
            for (const iterator of (data || [])) {                
                if (Object.prototype.toString.call(iterator) !== "[object String]") {   // Only Child entitites.
                    if (renderState.type.fields[props.fieldId].config.allowedTypes.find(typeId => typeId === iterator.typeId)) {

                        const v = {
                            type: await API.entityTypes.get(accessToken, iterator.typeId, getCache, setCache),
                            entity: structuredClone(iterator)
                        }

                        entities.push(v)
                    }
                }
            }

            // for (const iterator of renderState.type.fields[props.fieldId].config.allowedTypes) {
            //     if (renderState.type.fields[props.fieldId].config.refs[renderState.type.fields[props.fieldId].config.allowedTypes])
            //         entities.push(...(await getEntitiesByRef(iterator, `${renderState.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes]}:${props.entityId}`)))
            // }

            // console.log (entities)

            setList(entities)
        }                   

        asyncFunction(renderState.entity.data[fieldId])
    }, [])

    useEffect(() => {
        if (list == undefined)
            return

        if (renderState.type?.layoutsD[`field_list_${fieldId}`]) {
            setLayout(renderState.type?.layoutsD[`field_list_${fieldId}`].content)        
        } else {
            setLayout({
                build: {
                    type: "datagrid",                    
                    columns: [
                        {
                            type: "data",
                            field: "id",
                            headerName: "ID",
                            flex: 0.5
                        },
                        {
                            type: "data",
                            field: "typeId",
                            headerName: "TYPEID",
                            flex: 0.5
                        }
                    ]
                }
            })
        }

        // handleOnChange(formData) 
    }, [list])
    
    const onPatchCallback = (props) => {
        const { entityId, payload } = props

        setRenderState((prevState) => {
            const newState = prevState
            const index = prevState.entity.data[fieldId].findIndex(o => o.id === entityId)
            newState.entity.data[fieldId][index] = payload
            return newState
        })        
    }
    
    const onChangeCallback = (props) => {
        const { entityId, payload } = props
        
        if (list == undefined || payload == undefined)
            return
        
        setList((prevState) => {          
            prevState[prevState.findIndex(o => o.entity.id === entityId)].entity = payload.entity
            return prevState
        })
        
        onChange({fieldId: fieldId, entityId: props.entityId, payload: props.payload})
    }

    if (!layout) 
        return (<SimpleUICommonReactInitializing></SimpleUICommonReactInitializing>)

    return (
        <Item
            list={list} 
            layout={layout} 
                    
            onSubscribe={(props) => {subscribe(props)}} 
            onChange={onChangeCallback}
            onPatch={onPatchCallback}
        />
    )
}

export default Component