// --------------------------------------------------------- REACT ------
import React, {memo} from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// import {
//     TextField,
// } from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import { useRenderState } from './../../../../../../components/context/renderStateProvider'
import String from './string'
import Richtext from './richtext'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
export default (props) => {
    const { fieldId } = props
    const { renderState, onChange } = useRenderState()
    // console.log ("Render > Textfield:"+ fieldId)

    switch (renderState.type.fields[fieldId].subType) {   
        case "string": {
            return (
                <String
                    key={fieldId}
                    type={renderState.type.fields[fieldId]}
                    form={renderState.form}
                    fieldId={fieldId}
                    value={renderState.entity.data[fieldId]}
                    onChange={onChange}
                />
            )
        }     
        
        case "email": {
            return (
                <String
                    key={fieldId}
                    type={renderState.type.fields[fieldId]}
                    form={renderState.form}
                    fieldId={fieldId}
                    value={renderState.entity.data[fieldId]}
                    onChange={onChange}                 
                />
            )
        }

        // case "number": {
        //     return (<React.Fragment></React.Fragment>)
        // }    

        // case "password": {
        //     return (<React.Fragment></React.Fragment>)
        // }     

        // case "mask": {
        //     return (<React.Fragment></React.Fragment>)
        // }

        // case "multiline": {
        //     return (<React.Fragment></React.Fragment>)
        // }     

        // case "richtext": {
        //     return (
        //         <Richtext
        //             {...props}                        
        //         />
        //     )
        // }     

        default: {
            return (<React.Fragment></React.Fragment>)
        }    
    }
}