// --------------------------------------------------------- REACT ------
import React, { memo } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import {useRenderState} from './../../../../../../components/context/renderStateProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const quillModules = {
    toolbar: [          
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                      
    ],
}
// ----------------------------------------------------------------------
const Component = (props) => {
    const {fieldId, onChange} = props
    const {renderState} = useRenderState()    
    return (
        <ReactQuill
            theme="snow" 
            modules={quillModules} 
            value={renderState.entity.data[fieldId]}
            onChange={(value) => onChange({target: {name: fieldId, value: value}})}
        />
    )
}
export default memo(Component)