import React, { useState, useEffect, memo, Fragment } from 'react'

import {
    Box,
    Button,
    IconButton
} from '@mui/material/'

import { Icon} from '@mui/material'

import {
    Message as EditIcon,
} from '@mui/icons-material/'

import {useRenderState} from './../../../../components/context/renderStateProvider'

import Modal from './modal'
import StringFormat from './types/stringFormat'
import TextField from './types/textfield/'
import Boolean from './types/boolean'
import Select from './types/select/'
import Upload from './types/upload/'
import List from './types/list/'

const Field = memo((props) => {
    const { fieldId } = props
    const { renderState } = useRenderState()

    switch (renderState.type.fields[fieldId].type) {
        case "stringformat": {
            return (<StringFormat fieldId={fieldId}/>)
        }
        
        case "textfield": {
            return (<TextField fieldId={fieldId}/>)
        }

        // case "boolean": {
        //     return (<Boolean fieldId={fieldId} onChange={onChange}/>)
        // }
            
        // case "select": { 
        //      return (<Select fieldId={fieldId} onChange={onChange}/>)
        // }
        
        case "upload": {
            return (<Upload fieldId={fieldId}/>)
        }

        case "list": {
            return (<List fieldId={fieldId}/>)
        }

        default: {
            return (<React.Fragment/>)
        }
    }
})

const Component = (props) => {
    const { fieldId, modal, modalIcon } = props
    const [modalState, setModalState] = useState({open: false})

    const { renderState } = useRenderState()
    
    if (modal) {
        const handleOpenModal = () => {
            setModalState({
                open: true, 
                fullWidth: true,           
                children: (
                    <Box sx={{ p:2 }}>
                        <Field {...props}/>
                        <Button variant="contained" fullWidth>Close</Button>
                    </Box>
                )
            })
        }

        return (            
            <Fragment>
                <Modal state={modalState}  setState={setModalState} />
                <IconButton aria-label="open" size="small" onClick={handleOpenModal}>
                    <Icon fontSize="inherit" color={(renderState.entity.data[fieldId] !== "") ? "success" : ""}>{modalIcon}</Icon>
                </IconButton>                
            </Fragment>
        )   
    } else {
        return (
            <Field {...props}/>
        )
    }
}

export default memo(Component)
