import React from 'react'

import {
    TextField,
} from '@mui/material/'

import { renderStateMemo } from './../../../../../../components/context/renderStateProvider'

const Component = (props) => {
    const {fieldId, type, value, form, onChange } = props
    console.log ("Render.Element.Textfield.String:"+ fieldId)

    return (
        <TextField
            id={fieldId}

            label={type.label}

            value={value}
            onChange={onChange}
        
            required={type.required}
            
            variant="outlined"
            margin="dense"                        
            fullWidth={true}
            
            inputProps={{ maxLength: type.maxLength || 250}}
            
            disabled={(form.locked || form.disabled)}
            
            autoComplete="off"
            
            error={(form.fieldError[fieldId] || {}).error}

            helperText={(form.fieldError[fieldId] || {}).text}
        />
    )
}

export default renderStateMemo(Component)
