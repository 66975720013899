import React, { useState, useEffect, Fragment } from 'react'

import {
    DialogContentText
} from '@mui/material/'

import {
    SimpleUIAuthState,
    SimpleUICommonReactInitializing,
    SimpleUIUseCache
} from './../../../../../../simpleUI'

import { API } from './../../../../../../components/common'
import { useRenderState } from './../../../../../../components/context/renderStateProvider'

import ListTable from './table/'
import ListDatagrid from './datagrid'
import ListAccordion from './accordion/'

const Component = (props) => {    
    const { fieldId } = props    

    const { accessToken } = SimpleUIAuthState()    
    const { renderState, setRenderState, onChange, subscribe } = useRenderState()
    const { getCache, setCache, deleteCache } = SimpleUIUseCache()

    const [ layout, setLayout ] = useState()
    const [ list, setList ] = useState(undefined)

    // const [dialogCreate, setDialogCreate] = useState(false)
    // const [dialogEdit, setDialogEdit] = useState(false)
    // const [dialogStateDelete, setDialogDelete] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    
    useEffect(() => {
        const asyncFunction = async (data) => {            
            const entities = []
            for (const iterator of (data || [])) {                
                if (Object.prototype.toString.call(iterator) !== "[object String]") {   // Only Child entitites.
                    if (renderState.type.fields[props.fieldId].config.allowedTypes.find(typeId => typeId === iterator.typeId)) {

                        const v = {
                            type: await API.entityTypes.get(accessToken, iterator.typeId, getCache, setCache),
                            entity: structuredClone(iterator)
                        }

                        entities.push(v)
                    }
                }
            }

            // for (const iterator of renderState.type.fields[props.fieldId].config.allowedTypes) {
            //     if (renderState.type.fields[props.fieldId].config.refs[renderState.type.fields[props.fieldId].config.allowedTypes])
            //         entities.push(...(await getEntitiesByRef(iterator, `${renderState.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes]}:${props.entityId}`)))
            // }

            // console.log (entities)

            setList(entities)
        }                   

        // asyncFunction(renderState.entity.data[fieldId])
    }, [])

    // useEffect(() => {
    //     console.log ("List Component")
    // }, [props])
    // const Buttons = (props) => {
        

    //     console.log (formData)
    //     const click = () => {
    //         console.log (props)

    //     }
    //     return (
    //         <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
    //             <IconButton aria-label="edit" size="small">
    //                 <EditIcon fontSize="inherit"/>
    //             </IconButton>
                    
    //             <IconButton aria-label="delete" size="small" onClick={click}>
    //                 <RemoveIcon fontSize="inherit"/>
    //             </IconButton>
    //         </Stack>
    //     )

    // }


    // console.log (props)

    // const getEntitiesByRef = async (entityTypeId, ref) => {
    //     let output = []    
    //     try {
    //         let getFetch = await fetch(`${API_URL_SIMPLECRM.ENTITIES}?typeId=${entityTypeId}&ref=${ref}`, {
    //             method: 'GET',
    //             headers: { 
    //                 'Content-Type': 'application/json',
    //                 'Authorization': "Bearer "+ accessToken
    //             }            
    //         })           

    //         if (!getFetch.ok)
    //             throw new Error((await getFetch.json()).error.code)

    //         output = await getFetch.json()
    //     } catch (error) {    
    //     }  

    //     return output
    // }

    useEffect(() => {
        // if (list == undefined)
        //     return

        // if (data == undefined)
        //     return

        if (renderState.type?.layoutsD[`field_list_${fieldId}`]) {
            setLayout(renderState.type?.layoutsD[`field_list_${fieldId}`].content)        
        } else {
            setLayout({
                build: {
                    type: "datagrid",                    
                    columns: [
                        {
                            type: "data",
                            field: "id",
                            headerName: "ID",
                            flex: 0.5
                        },
                        {
                            type: "data",
                            field: "typeId",
                            headerName: "TYPEID",
                            flex: 0.5
                        }
                    ]
                }
            })
        }

        // handleOnChange(formData) 
    }, [])

    // const onChangeCallback = (props) => {
    //     const { entityId, payload } = props

    //     // if (!payload)
    //     //     return       

    //     onChange({fieldId: fieldId, entityId: entityId, payload: payload})
    // }

    // const handleOnChange = (value) => {
    //     if (props.onChange)
    //         props.onChange({target: {name: props.fieldId, value: value}})
    // }
   
    // const handleAdd = (typeId) => {
    //     setDialogEdit({
    //         open: true,            
    //         children: (<Render typeId={typeId} entity={null} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
    //     })
    // }

    // const handleEdit = (entityId) => {
    //     if (formData.find(i => i === entityId)) {
    //         setDialogEdit({
    //             open: true,                
    //             children: (<Render entityId={entityId} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
    //         })
    //     } else if (formData.find(e => e.id === entityId)) {            
    //         setDialogEdit({
    //             open: true,                
    //             children: (<Render entity={formData.find((e) => e.id === entityId)} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
    //         })
    //     }
    // }
    
    // const createCallback = (type, entity) => {
    //     const updatedFormData = (formData) ? structuredClone(formData) : []
    //     updatedFormData.push(entity)
    //     setFormData(structuredClone(updatedFormData))
    // }

    // const test2 = (props) => {
    //     const { entityId, payload } = props

    //     if (!payload)
    //         return

    //     subscribe(props)
    // }

    // const onPatch = (props) => {
    //     const { entityId, payload } = props

    //     setRenderState((prevState) => {
    //         const newState = prevState
    //         const index = prevState.entity.data[fieldId].findIndex(o => o.id === entityId)
    //         newState.entity.data[fieldId][index] = payload
    //         return newState
    //     })
        
    // }
    
    // const onChangeCallback = (props) => {                
    //     const { entityId, payload } = props
        
    //     if (list == undefined || payload == undefined)
    //         return
        
    //     setList((prevState) => {          
    //         prevState[prevState.findIndex(o => o.entity.id === entityId)].entity = payload.entity
    //         return prevState
    //     })
        
    //     onChange({fieldId: fieldId, entityId: props.entityId, payload: props.payload})
    // }

    // const updateCallback = (entity, type) => {
    //     const updatedFormData = (formData) ? structuredClone(formData) : []
    //     updatedFormData[formData.findIndex(e => e.id === entity.id)] = entity
    //     setFormData(structuredClone(updatedFormData))
    // }

//     const handleDelete = (id) => {
//         setDialogDelete({
//             open: true,
//             title: "Delete entity",
//             children: (
//                 <DialogContentText id="alert-dialog-description">
//                     Are you sure you want to delete this item?
//                 </DialogContentText>),
//             button1Text: "No",
//             button2Text: "Yes",
//             onConfirm: async () => {

//                 // console.log()
// // 
//                 const newdata = structuredClone(formData)
                

//                 newdata.splice(newdata.findIndex((e) => e.id === id), 1)

//                 setFormData(structuredClone(newdata))

//                 // try {
//                 //     let entityDelete = await fetch(API_URL_SIMPLECRM.ENTITIES + id, {
//                 //         method: 'DELETE', 
//                 //         headers: {
//                 //             'Content-Type': 'application/json',
//                 //             'Authorization': "Bearer "+ accessToken
//                 //         }
//                 //     })  
        
//                 //     if (!entityDelete.ok)
//                 //         throw new Error((await entityDelete.json()).error.code)
        
//                 //     updateCallback()
//                 // } catch (error) {
//                 //     console.log (error)
//                 //     // handleError(error)
//                 // }                
//             },
//             onDecline: () => {}
//         })
//     }

    // const returnCallback = () => {
    //     setDialogCreate(false)
    //     setDialogEdit(false)
    // }

    if (!layout) 
        return (<SimpleUICommonReactInitializing></SimpleUICommonReactInitializing>)

    switch (layout.build.type) {
        case "table": {
        //     return (<ListTable layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete} state={props.state}/>)
            return (
                <ListTable fieldId={fieldId}/>
            )
        }

        case "accordion": { 
            
            // return (<ListAccordion list={list} layout={layout} onSubscribe={test2} onChange={onChangeCallback} onPatch={test3}/>) 
            return (
                <ListAccordion fieldId={fieldId}/>
            ) 
        }


        case "datagrid": {
            return (
                <React.Fragment>
                    {/* <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/> */}

                    {/* <Dialogg fullWidth={true} maxWidth={'md'} state={dialogEdit} setState={setDialogEdit}/> */}
                    <ListDatagrid fieldId={fieldId} layout={layout} list={list}/>
                </React.Fragment>            
            )
        }

        default: { return (<Fragment />) }
    }

    // return (
    //     <React.Fragment>
    //         <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>

    //         <Dialogg fullWidth={true} maxWidth={'md'} dialogState={dialogEdit} setDialogState={setDialogEdit}></Dialogg>

    //         <SimpleUICommonReactSwitch condition={layout.content.type}>
    //             <SimpleUICommonReactCase value={"datagrid"}>
    //                 <Default layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></Default>
    //             </SimpleUICommonReactCase>
    //             <SimpleUICommonReactCase value={"accordion"}>
    //                 <ListAccordion layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></ListAccordion>
    //             </SimpleUICommonReactCase>
    //             <SimpleUICommonReactCase value={"table"}>
    //                 <ListTable layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></ListTable>
    //             </SimpleUICommonReactCase>
    //         </SimpleUICommonReactSwitch>
    //     </React.Fragment>
    // )
}

export default Component