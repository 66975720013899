// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom"
import { memo } from 'react';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    Box,
    Paper,
    CircularProgress    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// import {} from '../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUICacheProvider
} from './../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './components/render'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const { typeId, entityId } = props    

    const params = useParams()
    const navigate = useNavigate()

    const [componentState, setComponentState] = useState({isInitializing: true})
    const [componentData, setComponentData] = useState()

    useEffect(() => {
        setComponentData({
            typeId: params.typeId || typeId, 
            entityId: ((entityId ? entityId : (params.entityId ? (params.entityId == "00000000-0000-0000-0000-000000000000" ? undefined : params.entityId ) : null)))
        })
    }, [])

    useEffect(() => {
        if (!componentData) 
            return 

        // console.log (componentData)

        setComponentState({ ...componentState, isInitializing: false })
    },[componentData])

    const createCallback = (type, entity) => {
        // if (
        //     (props.typeId) && 
        //     (type?.navigation?.url)
        // ) {
        //     navigate(`${type.navigation.url}/${entity.id}`)
        // } else {
            navigate('/entities/'+ entity.id)
        // }
    }

    const updateCallback = (type, entity) => {}

    const returnCallback = () => {
        navigate(-1)
    }

    if (componentState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 

    return (
        <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100%)',
                    width: '100%'
                }}
            >
            <SimpleUICacheProvider>
                <Render 
                    typeId={componentData.typeId} 
                    entityId={componentData.entityId} 
                    
                    createCallback={createCallback} 
                    updateCallback={updateCallback} 
                    returnCallback={returnCallback}

                    preload={true}
                />
            </SimpleUICacheProvider>

        </Paper>
    )
}
