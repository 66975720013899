import React, { useState, useEffect, memo, Fragment, useCallback } from 'react'

import {
    Stack,
    Button,
    IconButton,
    Collapse,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material/'

import LoadingButton from '@mui/lab/LoadingButton'

import {
    Close as CloseIcon,
} from '@mui/icons-material/'

import {
    SimpleUIAuthState,
    SimpleUIUseCache,

    SimpleUIBusy,
    SimpleUIDialogListSelector,
} from './../../../simpleUI'

import { RenderStateProvider, useRenderState } from './../../../components/context/renderStateProvider'
import Element from './element'

const Buttons = (props) => {
    const { handleOnClickMainButton, handleReturn } = props
    const { renderState } = useRenderState()
    return (
        <Fragment>
            <LoadingButton color="inherit" loading={renderState.form.mainButtonLoading} disabled={!renderState.form.dirty || renderState.form.locked} onClick={handleOnClickMainButton}>
                {(renderState.entity === undefined)
                    ? <Fragment>{(!renderState.entity?.id) ? "Create" : "Save" }</Fragment>
                    : <Fragment>{(!renderState.entity?.id) ? "Add" : "Apply" }</Fragment>
                }                
            </LoadingButton>          
            <Button color="inherit" onClick={handleReturn} disabled={renderState.form.disabled}>
                Close
            </Button>
        </Fragment>
    )
}

const Test2 = (props) => {
    let containsRenderPlaceholder = false

    const deepSearchChildren = (children) => {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;

            if (child.props?.id === 'renderplaceholder') {
                containsRenderPlaceholder = true
                return props.output;
            }

            if (child.props && child.props.children) {
                return React.cloneElement(child, {
                    ...child.props,
                    children: deepSearchChildren(child.props.children),
                });
            }

            return child;
        });
    };

    const modifiedChildren = deepSearchChildren(props.children);    

    return (
        <Fragment>
            {modifiedChildren}
            {!containsRenderPlaceholder && props.output}
        </Fragment>
    );
};

const Test3 = (props) => {
    let containsAccordion = false

    const deepSearchChildren = (children) => {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;

            if (child.type === Accordion) {
                containsAccordion = true
                return props.output;
            }

            if (child.props && child.props.children) {
                return React.cloneElement(child, {
                    ...child.props,
                    children: deepSearchChildren(child.props.children),
                });
            }

            return child;
        });
    };

    const modifiedChildren = deepSearchChildren(props.children);    

    return (
        <Fragment>
            {modifiedChildren}
            {!containsAccordion && props.output}
        </Fragment>
    );
};

const Tester = (props) => {
    // console.log (props)
    let found = false

    const searchForChild = (props) => {
        return React.Children.map(props.test, (child) => {
            child.children = searchForChild({output: props.output, test: child.children})
            if (child.type === Accordion) {
                found = true
                return props.output
            }        
            return child
        })
    }

    const childImages = searchForChild(props)
    
    if (found) {
        return (
            <Fragment>
                Inserted
                {childImages}
            </Fragment>
        )
    } 
    return (
        <Fragment>
                {props.output}
        </Fragment>
    )

}

const Component = (props) => {  
    const {createCallback, updateCallback, returnCallback} = props

    const {accessToken} = SimpleUIAuthState()
    const {cache, setCacheData} = SimpleUIUseCache()

    const {
        renderState, 
        
        setRenderState, 
        
        createEntity,
        patchEntity,

        releaseLock,

        onError, 
        dismissError
    } = useRenderState()

    const [layout, setLayout] = useState(undefined)

    // const [selectorOpenAction, setSelectorOpenAction] = React.useState(false)
    
    useEffect(() => {
        // if (!renderState?.form.child)
        //     console.log ("Render")

        // if (props.children) {
        //     let childImages = React.Children.map(props.children, (child) => {
        //         if (child.type === AccordionDetails)
        //             console.log ("--")
        //         return child
        //       });
        // }
            // console.log(props.children.find(child => child.type === AccordionSummary))
            // console.log (props.children)
            
    })

    useEffect(() => {
        if (!renderState)
            return

        if (renderState.type.layoutsD?.edit?.content) {
            setLayout(structuredClone(renderState.type.layoutsD.edit.content))
        } else {
                let layout = {
                    version: 1,
                    build: [
                        {
                            type: "grid",
                            props: {
                                container: true
                            },                            
                            content: [
                                {
                                    type: "grid",
                                    props: {
                                        item: true,
                                        xs: 12
                                    },
                                    content: []
                                }                                
                            ]
                        }
                    ]
                }
            
                Object.keys(renderState.type.fields).forEach(fieldId => {
                    layout.build[0].content[0].content.push({
                        type: "field",
                        value: fieldId
                    })        
                })

                setLayout(structuredClone(layout))
        }
    }, [renderState])
    
    const handleReturn = async () => {
        if (renderState.lock)
            releaseLock(accessToken)

        // if (props.entity === undefined)
        //     await handleUnlock()

        // if (props.onChange)
        //     return

        if (returnCallback)
            returnCallback()
    }    

    // const handleSelectType = async (data) => {
    //     try {
    //         let getType = await fetch(`${API_URL_SIMPLECRM.TYPES}${data[0]}`, {
    //             method: 'GET',
    //             headers: { 
    //                 'Content-Type': 'application/json',
    //                 'Authorization': "Bearer "+ accessToken
    //             }            
    //         })           

    //         if (!getType.ok)
    //            throw new Error((await getType.json()).error.code)

    //         setType(await getType.json())
    //         setEntity({data:{}})            
    //     } catch (error) {
    //         onError(error)
    //     }
    // }

    // const handleOnRendersChange = useCallback((props) => {    
    //     console.log ("handleOnRendersChange")    
    //     setRenders((prevState) => {
    //         const newState = [...prevState]
    //         const index = prevState.findIndex(o => o.id === props.id)
    //         if (index > -1)
    //             newState[index] = props
    //         else
    //             newState.push(props)

    //         return newState
    //     })
    // }, [renders])

    // const handleOnClickMainButton = useCallback(() => {
    //     if ((!entity?.id))
    //         return handleCreate()
    //     else
    //         return handleUpdate()        
    // }, [entity, entityData])

    const handleOnClickMainButton = useCallback(() => {
        if ((!renderState.entity?.id))
            return createEntity(accessToken)
        else
            return patchEntity(accessToken)
    }, [renderState])

    // useEffect(() => {
    //     // if (props.setDialogState) {
    //     //     props.setDialogState(prevState => {
    //     //     let newState = { ...prevState, buttons: Buttons }
    //     //     return newState
    //     // })

    //     if (props.dialogButtons) {
    //         console.log ("---")
    //         props.setDialogButtons(prevState => {
    //         let newState = { ...prevState, buttons: Buttons }
    //         return newState
    //     })
    // }
    // }, [formState])
    // #endregion

    // if (props.setDialogButtons)
    //     props.setDialogButtons((<Buttons handleOnClickMainButton={handleOnClickMainButton} handleReturn={handleReturn}></Buttons>))

    // if (
    //     (renderState == null)
    //     // (type === undefined) ||
    //     // (layout === undefined) ||
    //     // (entity === undefined) 
    //     // (entityData === undefined)
    // ) {        
    //     return (
    //         <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
    //             <SimpleUIDialogListSelector 
    //                 open={selectorOpenAction}
    //                 setOpen={setSelectorOpenAction}
                    
    //                 title="Please select Type"
    //                 button1Text="Cancel"
    //                 button2Text="Select"

    //                 columns={[{ field: 'name', headerName: 'Title', flex: 1 }]}                    

    //                 fetchRows={handleFetchTypes}

    //                 onSelect={handleSelectType}
    //                 onCancel={handleReturn}
    //             />   

    //             <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
    //         </Box>                  
    //     ) 
    // }    
        
    if (!layout)
        return (<SimpleUIBusy/>) 

    const output = (
        <Fragment>
            <Collapse in={renderState.form.error}>
                <Alert variant="filled" severity="error" sx={{mb: 2}}
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={dismissError}>
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {renderState.form.errorText}
                </Alert>
            </Collapse>

            <Collapse in={renderState.form.warning}>
                <Alert variant="filled" severity="warning" sx={{mb: 2}}>
                    {renderState.form.warningText}
                </Alert>
            </Collapse>
                

            {(!renderState.form.child) &&
                <Stack spacing={2} direction="row" justifyContent="end" sx={{ pb: '20px' }}>
                    <Buttons handleOnClickMainButton={handleOnClickMainButton} handleReturn={handleReturn}/>
                </Stack>
            }

            {layout.build.map((element, index) => (
                <Element
                    key={index}
                    element={element}
                    parentType={undefined}                        
                >
                </Element>
            ))}
        </Fragment>
    )

    return (
        <Fragment>
            <Test2 output={output}>
                {props.children}
            </Test2>
            {/* {output} */}
            

            {/* <Collapse in={renderState.form.error}>
                <Alert variant="filled" severity="error" sx={{mb: 2}}
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={dismissError}>
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {renderState.form.errorText}
                </Alert>
            </Collapse>

            <Collapse in={renderState.form.warning}>
                <Alert variant="filled" severity="warning" sx={{mb: 2}}>
                    {renderState.form.warningText}
                </Alert>
            </Collapse>
                

            {(!renderState.form.child) &&
                <Stack spacing={2} direction="row" justifyContent="end" sx={{ pb: '20px' }}>
                    <Buttons handleOnClickMainButton={handleOnClickMainButton} handleReturn={handleReturn}/>
                </Stack>
            }

            {layout.build.map((element, index) => (
                <Element
                    key={index}
                    element={element}
                    parentType={undefined}                        
                >
                </Element>
            ))} */}
        </Fragment>
    )
}

export default memo((props) => {
    return (
        <RenderStateProvider {...props}>         
            <Component {...props}/>
        </RenderStateProvider>
    )
})