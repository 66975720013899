// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    CircularProgress,
    Fade,
    Fab,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon,     
    CloudDownload as DownloadIcon,
    PhotoCamera as UploadIcon,
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
import {
    SimpleUIAuthState,
} from './../../../../../../simpleUI'
import { useRenderState, renderStateMemo } from './../../../../../../components/context/renderStateProvider'
import { API } from './../../../../../../components/common'
import storage from '../../../../../../components/API/storage'


const Image = renderStateMemo((props) => {
    const { entityId, file, fieldId, storageId, form, onGetPreview, onDownloadFile, onRemoveFile, onError, uploadCallback } = props

    const [uploadProgress, setUploadProgress] = useState(0)    
    const { accessToken } = SimpleUIAuthState()    

    // const uploadProgress = 0
    const meta = ""

    const [preview, setPreview] = useState()    
    const [hover, setHover] = useState(false)

    useEffect(() => {
        // No storageId means upload has ben initiated.
        if (!storageId) {
            setUploadProgress(1)
            return
            
            const payload = new FormData()
            payload.append('FILE', file)
            API.storage.upload(accessToken, entityId, fieldId, payload)
            .then(result => {
                
                // uploadCallback(result.storageId)
                setUploadProgress(0)
               

                onGetPreview(result.storageId)
        .then (result => {
            console.log ("done")
            setPreview(result)
        }).catch(error => {
            onError (error)
        })
            })

            return
        }

        

        onGetPreview(storageId)
        .then (result => {
            setPreview(result)
        }).catch(error => {
            onError (error)
        })
    }, [])

    const handleMouseEnter = () => {
        if (!(form.locked || form.disabled) && storageId)
            setHover(true)
    }

    const handleMouseLeave = () => {
        if (!(form.locked || form.disabled))
            setHover(false)
    }    

    return (
        <React.Fragment>
            <Box 
                display="flex" 
                position="relative" 
                justifyContent="center" 
                
                onMouseOver={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                        
                sx={{
                    mt: 1,
                    mr: 1,                
                    alignItems: 'center', 
                    height: '100px', 
                    width: '100px', 
                    minHeight: '100px', 
                    minWidth: '100px', 
                    border: 1, 
                    borderRadius: 2, 
                    borderColor: '#bbbbbb',
                    overflow: 'hidden'
                }}
            >                
                <Fade in={(form.locked || form.disabled || (uploadProgress > 0 && uploadProgress < 100))}>
                    <Box position="absolute" right="0px"
                        sx={{
                            zIndex: 1000,                
                            backgroundSize: "cover",
                            backgroundColor: "rgba(255,255,255,0.3)",
                            backgroundRepeat: "no-repeat",                                
                            height: "100%",
                            width: "100%",                                    
                        }}                                    
                    />
                </Fade>

                <Fade in={preview}>
                    <Box 
                        sx={{                                
                            backgroundSize: "cover",
                            backgroundImage: `url("${preview || ""}")`,                                
                            backgroundRepeat: "no-repeat",                                
                            height: "100%",
                            width: "100%",                                    
                        }}                                    
                    />
                </Fade>

                <Fade in={hover}>
                    <Box
                        display="flex"                         
                        sx={{                        
                            alignItems: 'center', 
                        }}
                    >
                        <Box m={1} position="absolute" left="0px">                                                        
                            <Fab 
                                size="small" 
                                color="primary" 
                                onClick={() => {onDownloadFile(storageId)}}

                                disabled={((Array.isArray(meta)) ? !(meta.find((e) => e.filename == storageId)) : false) }
                            >
                                <DownloadIcon fontSize='small'/>
                            </Fab>
                        </Box>
                        <Box m={1} position="absolute" right="0px">                                                        
                            <Fab 
                                size="small" 
                                color="error" 
                                onClick={() => {onRemoveFile(props.storageId)}}
                            >
                                <RemoveIcon fontSize='small'/>
                            </Fab>
                        </Box>
                    </Box>
                </Fade>    

                <Fade in={(uploadProgress > 0)}>
                    <Box position="absolute">
                        <CircularProgress  />
                    </Box>
                </Fade>                  
            </Box>
        </React.Fragment>
    )
})

export default (props) => {
    const { fieldId, value, form, uploadProgress } = props

    const { renderState, onChange } = useRenderState()

    const [list, setList] = useState([])

    const handleUpload = (event) => {

        // setUploadProgress(1)
        
        // const promises = []

        console.log ("Handle Upload")



        const newItems = []

        for (let i = 0; i < event.target.files.length; i++) {      
            
            // if (i == 10)
            //     break

            newItems.push({
                "file": event.target.files[i]
            })
        }
        setList(list.concat(newItems))
        
        // setList((prevState) => {                    
        //     const newState = prevState
        //     for (let i = 0; i < event.target.files.length; i++) {    
        //         newState.push({
        //             "blob": ""
        //         })
        //     }
        //     console.log (newState)
        //     return newState
        // })

        
        // for (let i = 0; i < event.target.files.length; i++) {

        //     setUploadProgress(((100/event.target.files.length) * i))
    
        //     const payload = new FormData()
        //     payload.append('FILE', event.target.files[i])
                

        //     promises.push(API.storage.upload(accessToken, renderState.entity.id, fieldId, payload))

        //     // try {
        //     //     newStorageIds.push((await API.storage.upload(accessToken, renderState.entity.id, fieldId, payload)).storageId)    
        //     // } catch (error) {
        //     //     handleError(error)
        //     // }
        // }

        // Promise.all(promises)
        // .then(result => {
        //     let newStorageIds = []
        //     for (const upload of result) {
        //         newStorageIds.push(upload.storageId)
        //     }
        //     onChange({target: {name: fieldId, value: (renderState.entity.data[fieldId] || []).concat(newStorageIds) }})
        //     setUploadProgress(0)
        // })
        // .catch((error) => {
        //     console.log ("error")
        // })


    }

    const uploadCallback = (storageId) => {
        // setList(list.concat([{storageId: storageId}]))

        onChange({target: {name: fieldId, value: (renderState.entity.data[fieldId] || []).concat([storageId]) }})
    }

    return (
        <React.Fragment>
            <input style={{display: 'none'}} 
                id={`${fieldId}-file`}
                type="file"
                multiple 
                accept={renderState.type.fields[fieldId].mimetypes.toString()}
                        
                onChange={handleUpload}                
                disabled={(renderState.form.locked || renderState.form.disabled)}
            />
        
            <Box
                sx={{   
                   
                    display: 'flex',
                    flexWrap: 'wrap',                    
                    pl: 1,
                    pb: 1,
                    width: '100%',                    
                    border: 1, 
                    borderRadius: 2, 
                    borderColor: '#bbbbbb'                    
                }}
            >        

                {/* <Image
                    form={renderState.form}
                /> */}

                {list.map((item, index) => (
                    <Image     
                        {...props}                      
                        key={`${index}`}
                        storageId={item.storageId}
                        file={item.file}
                        fieldId={fieldId}
                        entityId={renderState.entity.id}
                        uploadCallback={uploadCallback}
                        // onGetPreview={props.onGetPreview}
                        // onDownloadImage={props.onDownloadFile}
                        // onRemoveImage={props.onRemoveFile}
                        // uploadProgress={props.uploadProgress}                            
                        // meta={props.meta}
                    />
                ))}
                                

                {/* {(value || []).map(function(storageId) {
                    return (
                        <Image
                            {...props}

                            key={storageId}                         
                            storageId={storageId}
                            // onGetPreview={props.onGetPreview}
                            // onDownloadImage={props.onDownloadFile}
                            // onRemoveImage={props.onRemoveFile}
                            // uploadProgress={props.uploadProgress}                            
                            // meta={props.meta}
                        />
                    )
                })} */}
                <Box
                    display="flex" 
                    position="relative" 
                    justifyContent="center" 

                    sx={{
                        mt: 1,
                        mr: 1,
                        alignItems: 'center', 
                        height: '100px', 
                        width: '100px', 
                        minHeight: '100px', 
                        minWidth: '100px', 
                        border: 1, 
                        borderRadius: 2, 
                        borderColor: '#bbbbbb',
                        overflow: 'hidden'
                    }}
                >
                        
                    <Fade in={(uploadProgress == 0)}>
                        <Box m={1} position="absolute">
                            <label htmlFor={fieldId +"-file"}>
                                <Fab
                                    component="span" 
                                    size="small" 
                                    color="success"                                     
                                    disabled={(form.locked || form.disabled)}
                                >
                                    <UploadIcon fontSize='small'/>
                                </Fab>
                            </label>
                        </Box>
                    </Fade>

                    <Fade in={(uploadProgress > 0)}>
                        <Box position="absolute">
                            <CircularProgress  />
                        </Box>
                    </Fade>                                                
                </Box>
            </Box>    
                                        
        </React.Fragment>
    )   
}