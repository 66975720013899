import React, { createContext, useCallback, useContext, useState, useEffect, memo } from "react"
import isEqual from 'lodash/isEqual'

import {
    SimpleUIAuthState,
    SimpleUIUseCache,
    SimpleUICommonUseInterval
} from './../../simpleUI'

import { 
    API,
    setStateKeyValuePair, 
} from './../common'

const RenderStateContext = createContext()

export const RenderStateProvider = (props) => {
    const { type, typeId, entity, entityId, onPatchCallback, onChangeCallback, children, onSubscribeCallback, preload } = props

    const { accessToken } = SimpleUIAuthState()
    const [ renderState, setRenderState ] = useState(undefined)

    const [ subscribers, setSubscribers ] = useState([])

    const [ childStates, setChildStates ] = useState([])

    // const {cache, setCacheData} = SimpleUIUseCache()
    const { getCache, setCache, deleteCache } = SimpleUIUseCache();

    useEffect(() => {
        const asyncFunction = async () => {
            const initialState = {
                form: {
                    isInitializing: true,
            
                    mode: undefined,
                    child: (onChangeCallback ? true : false),
                    modal: false,
             
                    mainButtonDisabled: true,
                    mainButtonLoading: false,
            
                    disabled: false,
                    locked: false,
            
                    valid: false,
                    dirty: false,
            
                    warning: false,
                    warningText: "",
            
                    error: false,
                    errorText: "",
            
                    fieldError: {}
                },
                type: undefined,
                entity: undefined,
                lock: undefined,
                default: undefined,
                children: []
            }

            // #region Create
            if ((typeId || type) && !entityId && !entity) {
                initialState.form.mode = "create"
                initialState.type = typeId && !type ? await API.entityTypes.get(accessToken, typeId, getCache, setCache) : type
                initialState.entity = { id: null, typeId: initialState.type.id, data: {} }
                initialState.lock = false
                initialState.default = structuredClone(initialState.entity)
                setRenderState(initialState)
                return
            }
            // #endregion

            // #region Edit
            if (entityId || entity) {
                initialState.form.mode = "edit"
                initialState.entity = entityId && !entity ? await API.entities.get(accessToken, entityId, getCache, setCache) : entity
                initialState.type = (type && type.id == entity.typeId) ? type : await API.entityTypes.get(accessToken, initialState.entity.typeId, getCache, setCache)
                initialState.lock = onChangeCallback ? true : await API.entities.acquireLock(accessToken, initialState.entity.id)                
                initialState.default = structuredClone(initialState.entity)
               
                // Preload Types.
                if (preload) {
                    for (const typePreload of initialState.type.preload.types) {
                        await API.entityTypes.get(accessToken, typePreload, getCache, setCache)
                    }
                }

                setRenderState(initialState)
                return
            }
            // #endregion
        }

        asyncFunction()
    }, [])

    useEffect(() => {
        // if (!renderState)
        //     return

        if (onChangeCallback)
            onChangeCallback(structuredClone(renderState))

        if (onSubscribeCallback)
            onSubscribeCallback({patch: patchEntity})

    }, [renderState, childStates])
   
    SimpleUICommonUseInterval(() => {
        if (!renderState || onChangeCallback)
            return

        if (renderState.form.mode == "edit") {            
            API.entities.acquireLock(accessToken, renderState.entity.id)
            .then (lock => {
                if (lock !== renderState.lock) {
                    setRenderState((prevState) => {
                        return setStateKeyValuePair(prevState, {
                            ["lock"]: lock,
                            ["form.locked"]: lock ? false : true,
                            ["form.warning"]: lock ? false : true,
                            ["form.warningText"]: lock ? "" : "This "+ renderState.type.name +" has been locked by another user. Lock will be acquired when released."
                        })
                    })
                }       
            })            
        }
    }, 10000)

    const handleError = (error) => {
        console.log (error)
    }

    const validateEntityData = (props) => {
        const { entity, children } = props
        
        let formValid = 0
        let formDirty = 0        
        let formValidChildren = 0
        let formDirtyChildren = 0

        // Validate: CHANGED
        if (JSON.stringify(renderState.default.data) !== JSON.stringify(entity.data)) {
            formDirty++
        }
                
        // Validate: REQUIRED FIELDS        
        for (var fieldId in renderState.type.fields) {
            const field = renderState.type.fields[fieldId]

            if (field.required) {
                if (!entity.data.hasOwnProperty(fieldId)) {
                    formValid++
                    continue
                }
                    
                switch (field.type) {
                    case "select": {
                        if (Array.isArray(entity.data[fieldId]) && entity.data[fieldId].length == 0) {
                            formValid++
                        } else if (entity.data[fieldId] instanceof Object) {
                            formValid++
                            for (var option in entity.data[fieldId]) {
                                if (entity.data[fieldId][option]) {
                                    formValid--
                                    break
                                }
                            }
                        } else if (entity.data[fieldId] == "") {
                            formValid++
                        }                           
                        break
                    }

                    case "upload": {
                        break
                    }

                    default: {
                        if (entity.data[fieldId] == "")
                            formValid++
                    }
                }
            }
        }

        // Validate: Children
        for (const child of children) {
            if (child.dirty)
                formDirty++

            if (child.valid)
                formValid++
        }
    
        // console.log ({
        //     valid: !!(formValid),
        //     dirty: !!(formDirty),
        // })

        return { valid: !!(formValid), dirty: !!(formDirty) }
    }

    const createEntity = async (accessToken) => {
        // setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        // if (props.entity !== undefined) {            
        //     const createdEntity = structuredClone(entity)            
        //     createdEntity.typeId = type.id
        //     createdEntity.data = structuredClone(entityData)
        //     setEntity(structuredClone(createdEntity))

        //     if (props.createCallback)
        //         props.createCallback(structuredClone(type), structuredClone(createdEntity))

        //     handleReturn()

        //     return structuredClone(createdEntity)
        // } else {
            try {            
                const newEntity = await API.entities.post(accessToken, renderState.type.id, renderState.entity.data)
                
                setRenderState((prevState) => {                    
                    return setStateKeyValuePair(prevState, 
                        {   
                            ["form.warning"]: false,
                            ["form.warningText"]: "",
    
                            ["form.error"]: false,
                            ["form.errorText"]: "",
    
                            ["form.fieldError"]: {},
                            ["form.valid"]: true,
                            ["form.dirty"]: false,

                            ["entity"]: structuredClone(newEntity),
                            ["default"]: structuredClone(newEntity),
                        }
                    )
                })


            } catch (error) {    
                console.log (error)                  
                onError(error)
            }  
        // }      

        return null
    }

    const patchEntity = async (accessToken) => {
        // setFormState({ ...formState, disabled: true, mainButtonLoading: true })
            
        // if (entity !== undefined) {
        //     const updatedEntity = structuredClone(entity)
        //     // updatedEntity.data = structuredClone(entityData)
        //     // setEntity(structuredClone(updatedEntity))

        //     // if (props.updateCallback)
        //     //     props.updateCallback(structuredClone(updatedEntity))
    
        //     handleReturn()
    
        //     return structuredClone(updatedEntity)
        // } else {
        
        for (const element of subscribers) {
            await element.patch(accessToken)
            // console.log ()
        }

        if (renderState.type.parent) {
            if (onPatchCallback)
                onPatchCallback(structuredClone(renderState.entity))

            return
        }


        try {
            await API.entities.patch(accessToken, renderState.entity)            

            // if (props.updateCallback)
            //     props.updateCallback(structuredClone(entity), structuredClone(type))

            setRenderState((prevState) => {                    
                return setStateKeyValuePair(prevState, 
                    {   
                        ["form.warning"]: false,
                        ["form.warningText"]: "",

                        ["form.error"]: false,
                        ["form.errorText"]: "",

                        ["form.fieldError"]: {},
                        ["form.valid"]: true,
                        ["form.dirty"]: false,
                        
                        ["default"]: structuredClone(prevState.entity),
                    }
                )
            })

            return renderState.entity
        } catch (error) {
            onError(error)
        }
    
        return null
    }

    const releaseLock = async (accessToken) => {
        return await API.entities.releaseLock(accessToken, renderState.entity.id)
    }

    const dismissError = () => {
        setRenderState((prevState) => {
            return setStateKeyValuePair(prevState, {
                ["form.error"]: false,
                ["form.errorText"]: ""
            })
        })
    }

    const onError = (error) => {
        error = (JSON.parse(error.message)).error
           
        let errorText = ""
        switch (error.code) {
            case "ER_ENTITY_IS_LOCKED_BY_OTHER_USER":
                errorText = "This entity is edited by another user."
                break

            case "ER_ENTITYTYPE_NOT_FOUND":
                errorText = "EntityType not found."
                break
    
            case "ER_ENTITY_NOT_FOUND":
                errorText = "Entity not found."
                break
    
            case "ER_ENTITY_DATA_ERROR":
                let messsage = JSON.parse(error.message)
                let fieldError = {}                
                fieldError[messsage.key] = {error: true, code: messsage.error.code, text: messsage.error.code}
                console.log ("*** DATA ERROR ***")
                break
                
            default:
                errorText = "An unexpected error occured."
        }

        setRenderState((prevState) => {
            return setStateKeyValuePair(prevState, {
                ["form.error"]: true,
                ["form.errorText"]: errorText
            })
        }) 

        // console.log (error.message)        
    }
       
    const onChange = useCallback((event) => {           
        // Check if event was trigged from a field or child render.       
        if (event.target) {
            const id = (event.target.name || event.target.id)
            const value = event.target.value

            setRenderState((prevState) => {
                const validationResult = validateEntityData(setStateKeyValuePair(prevState, {[`entity.data.${id}`]: value}))
                return setStateKeyValuePair(prevState, {
                    ["form.fieldError"]: {},
                    ["form.valid"]: validationResult.valid,
                    ["form.dirty"]: validationResult.dirty,
                                                            
                    ["entity.data." + id]: value,
                })
            })
        } else if (event.payload) {
            
            setRenderState((prevState) => {
                const children = prevState.children                
                const index = children.findIndex(o => o.id === event.entityId)
            
                const childState = { id: event.entityId, dirty: event.payload.form.dirty, valid: event.payload.form.valid }

                if (index > -1) {
                    children[index] = childState
                } else {
                    children.push(childState)
                }
    
                const validationResult = validateEntityData(setStateKeyValuePair(prevState, {["children"]: children}))

                const newState = {
                    ["form.fieldError"]: {},
                    ["form.valid"]: validationResult.valid,
                    ["form.dirty"]: validationResult.dirty,
                                                            
                    ["children"]: children,
                }

                // Child entities resides in their parent entities.
                // if (event.payload.type.parent) {
                //     // console.log ("this is a child entity!")                    
                //     if (Array.isArray(prevState.entity.data[event.fieldId])) {
                //         // console.log ("array of entities")
                        
                //         const newArray = structuredClone(prevState.entity.data[event.fieldId])                        
                //         const index2 = newArray.findIndex(o => o.id === event.entityId)
                //         newArray[index2] = event.payload.entity
                        
                //         newState["entity.data." + event.fieldId] = newArray
                //     } else {
                //         // console.log ("single entity")
                //         newState["entity.data." + event.fieldId] = event.payload.entity
                //     }
                // }
                    

                return setStateKeyValuePair(prevState, newState)
            })
        } 
        // else if (event.payload.form.dirty || !event.payload.form.valid) {
        //     console.log ("1")
        //     setRenderState((prevState) => {
        //         const children = prevState.children
        //         const index = children.findIndex(o => o.id === event.entityId)                                
        //         if (index > -1)
        //             children[index] = {id: event.entityId, dirty: event.payload.form.dirty, valid: event.payload.form.valid}
        //         else
        //             children.push({id: event.entityId, dirty: event.payload.form.dirty, valid: event.payload.form.valid})
    
        //         const validationResult = validateEntityData(setStateKeyValuePair(prevState, {["children"]: children}))

        //         return setStateKeyValuePair(prevState, {
        //             ["form.fieldError"]: {},
        //             ["form.valid"]: validationResult.valid,
        //             ["form.dirty"]: validationResult.dirty,
                                                            
        //             ["children"]: children,
        //         })
        //     })
        // } else if (!event.payload.form.dirty && event.payload.form.valid) {
        //     console.log ("2")
        //     setRenderState((prevState) => {
        //         const children = prevState.children.filter(o => o.id !== event.entityId)
        //         const validationResult = validateEntityData(setStateKeyValuePair(prevState, {["children"]: children}))

        //         return setStateKeyValuePair(prevState, {
        //             ["form.fieldError"]: {},
        //             ["form.valid"]: validationResult.valid,
        //             ["form.dirty"]: validationResult.dirty,
                                                            
        //             ["children"]: children,
        //         })
        //     })
        // }
            
        return true
    }, [renderState])

    const onRenderChange = useCallback((props) => {
        console.log ("handleOnRendersChange")  

        

        // onChange()
    }, [])
    
    const subscribe = useCallback((props) => {        
        const { entityId, payload } = props

        setSubscribers((prevState) => {            
            const index = prevState.findIndex(o => o.id === entityId)
            const subscriberState = { id: entityId, patch: payload.patch }

            if (index > -1) {
                prevState[index] = subscriberState
            } else {
                prevState.push(subscriberState)
            }            

            return prevState
        })
    }, [])

    return (
        <RenderStateContext.Provider value={{ 
            renderState, 
            setRenderState, 

            onChange, 
            onRenderChange,

            subscribe,

            createEntity,
            patchEntity,
            releaseLock, 

            onError, 
            dismissError 
        }}>
            {children}
        </RenderStateContext.Provider>
    )
}

export const renderStateMemo = (Component) => {
    return memo((props) => {return Component(props)},    
    (prevProps, nextProps) => {

        // console.log (prevProps)

        const deep_comparison_result = []
        const { value: prevValue, form: prevForm, ...prevRest } = prevProps
        const { value: nextValue, form: nextForm, ...nextRest } = nextProps

        deep_comparison_result.push(isEqual(prevValue, nextValue))
        deep_comparison_result.push(isEqual(prevForm, nextForm))

        // console.log (deep_comparison_result)

        return deep_comparison_result.every(element => element)
    })
}

export const useRenderState = () => {
    const context = useContext(RenderStateContext)
    if (!context)
        throw new Error("useRenderState must be used within a RenderStateProvider")
    return context
}
