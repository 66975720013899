// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Button,
    Typography,
    Modal
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
} from './../../../../../../simpleUI'

import { API } from './../../../../../../components/common'
import { useRenderState } from './../../../../../../components/context/renderStateProvider'

// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import File from './file'
import Files from './files'
import Image from './image'
import Images from './images'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_SIMPLEDATA = "/api/simplecrm/entities/"

export default (props) => {    
     const { fieldId } = props    
    
    const { accessToken } = SimpleUIAuthState()    
    const { renderState, onChange } = useRenderState()

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [meta, setMeta] = useState({})
    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {        
        if (renderState.entity.data[fieldId]) {
            API.storage.getMeta(accessToken, renderState.entity.id, fieldId)
            .then ((result) => {
                setMeta(result)
            })
            .catch((error) => {
                console.log (error)
                // setMeta((Array.isArray(props.data[props.fieldId])) ? [] : {originalname: "Error: File could not be found.", filename: props.data[props.fieldId], fileNotFound: true})
            })
        }

        setUploadProgress(0)
    }, [])      

    const handleUpload = async (event) => {
        setUploadProgress(1)
        
    
        
        const promises = []

        for (let i = 0; i < event.target.files.length; i++) {
            setUploadProgress(((100/event.target.files.length) * i))
    
            const payload = new FormData()
            payload.append('FILE', event.target.files[i])
                

            promises.push(API.storage.upload(accessToken, renderState.entity.id, fieldId, payload))

            // try {
            //     newStorageIds.push((await API.storage.upload(accessToken, renderState.entity.id, fieldId, payload)).storageId)    
            // } catch (error) {
            //     handleError(error)
            // }
        }

        Promise.all(promises)
        .then(result => {
            let newStorageIds = []
            for (const upload of result) {
                newStorageIds.push(upload.storageId)
            }
            onChange({target: {name: fieldId, value: (renderState.entity.data[fieldId] || []).concat(newStorageIds) }})
            setUploadProgress(0)
        })
        .catch((error) => {
            console.log ("error")
        })

        
    }

    const handleGetPreview = async (storageId) => {
        try {
            return await API.storage.getPreview(accessToken, renderState.entity.id, fieldId, storageId)
        } catch (error) {
            handleError(error)
        }
    }

    const handleUploadFile_OLD = async (event) => {
        
        // if (event.target.files.length == 1) {
        //     const data = new FormData()
        //     data.append('FILE', event.target.files[0])

        //     fetch(`${API_URL_SIMPLEDATA}${props.entityId}/storage/${props.fieldId}/`, {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': "Bearer "+ accessToken
        //         },
        //         body: data
        //     })       
        //     .then(result => result.json ())
        //     .then(json => {
        //         handleOnChange({
        //             value: ((Array.isArray(props.data[props.fieldId])) ? [...props.data[props.fieldId]].concat(json.storageId) : json.storageId)
        //         })

        //         // handleOnChange({
        //         //     value: ((Array.isArray(props.data[props.fieldId])) ? [...props.data[props.fieldId]].concat(json.storageId) : json.storageId)
        //         // })
        //     })
        //     .catch(error => {
        //         handleError (error)
        //     })
        // } else {
            let storageIds = []
            for (let i = 0; i < event.target.files.length; i++) {
                setUploadProgress(((100/event.target.files.length) * i))
    
                const data = new FormData()
                data.append('FILE', event.target.files[i])
                
                try {
                    let postStorage = await fetch(`${API_URL_SIMPLEDATA}${renderState.entity.id}/storage/${props.fieldId}/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                        body: data
                    })
    
                    if (!postStorage.ok)
                        throw new Error((await postStorage.json()).error.code)
                
                    storageIds.push((await postStorage.json()).storageId)    
                } catch (error) {
                    handleError(error)
                } 
            }
    
            onChange({target: {name: fieldId, value: [renderState.entity.data[fieldId] || []].concat(storageIds) }})

            // handleOnChange({                
            //     value: [...props.data[props.fieldId] || []].concat(storageIds)
            // })   

            setUploadProgress(0)
        // }
    }

    // const handleGetMeta = async () => {
    //     let getMeta = await fetch(`${API_URL_SIMPLEDATA}${renderState.entity.id}/storage/${fieldId}/meta/`, {
    //         method: 'GET',
    //         cache: "no-store",
    //         headers: {
    //             'Authorization': "Bearer "+ accessToken
    //         }
    //     })

    //     if (!getMeta.ok)
    //         throw new Error((await getMeta.json()).error.code)                

    //     return await getMeta.json()            
    // }

    // const handleGetPreview = async (storageId) => {
    //     try {            
    //         let preview = await fetch(`${API_URL_SIMPLEDATA}${renderState.entity.id}/storage/${fieldId}/preview/${storageId}`, {
    //             method: 'GET',
    //             cache: "no-store",
    //             headers: {
    //                 'Authorization': "Bearer "+ accessToken
    //             }
    //         })

    //         return URL.createObjectURL(await preview.blob())    
    //     } catch (error) {
    //         handleError (error)
    //     }
    // }

    const handleRemoveFile = (storageId) => {
        let newData = []
        // if (storageId) {

            // newData = fieldData.filter((id) => id != storageId)



            // setFiles([...newData])
        // } 

        // handleOnChange({
        //     value: newData
        // }) 
    }

    // const handleDownloadFile = async (storageId) => {
    //     if (!storageId)
    //         storageId = renderState.entity.data[fieldId]

    //     let getMeta = await fetch(`${API_URL_SIMPLEDATA}${renderState.entity.id}/storage/${fieldId}/meta/${storageId}`, {
    //         method: 'GET',                
    //         headers: {
    //             'Authorization': "Bearer "+ accessToken
    //         }
    //     })

    //     if (!getMeta.ok)
    //         throw new Error((await getMeta.json()).error.code)

    //     let meta = await getMeta.json()

    //     fetch(`${API_URL_SIMPLEDATA}${renderState.entity.id}/storage/${fieldId}/download/${storageId}`, {
    //         method: 'GET',            
    //         headers: {
    //             'Authorization': "Bearer "+ accessToken
    //         }
    //     })
    //     .then(result => result.blob())
    //     .then(blob => {
    //         const url = URL.createObjectURL(blob)
    //         const a = document.createElement("a")
    //         a.style.display = "none"
    //         a.href = url          
    //         a.download = meta.originalname
    //         document.body.appendChild(a)
    //         a.click()
    //         URL.revokeObjectURL(url)
    //     })
    //     .catch (error => {
    //         handleError (error)
    //     })
    // }

    const handleOnChange = (event) => {
        if (onChange)
            onChange({target: {name: fieldId, value: event.value}})
    }

    const handleError = (error) => {
        console.log (error)        
    }

    switch (renderState.type.fields[fieldId].subType) {
    // switch (props.type.fields[props.fieldId].subType) {        
        // case "file": {
        //     return (
        //         <React.Fragment>
        //             <input style={{ display: 'none' }}
                        
        //                 id={`${props.fieldId}-file`}                
        //                 type="file" 
        //                 accept={renderState.type.fields[props.fieldId].mimetypes.toString()}
                
        //                 onChange={handleUploadFile} 
        //                 disabled={(renderState.form.locked || renderState.form.disabled)}
        //             />
                
        //             <File 
        //                 {...props}
                        
        //                 onGetPreview={handleGetPreview}
        //                 onDownloadFile={handleDownloadFile}
        //                 onRemoveFile={handleRemoveFile}              
        //                 onError={handleError}

        //                 meta={meta}
        //                 uploadProgress={uploadProgress}
        //             />
        //         </React.Fragment>       
        //     )   
        // }

        // case "files": {
        //     return (
        //         <React.Fragment>
        //             <input style={{ display: 'none' }} 
        //                 id={`${props.fieldId}-file`}
        //                 type="file" 
        //                 multiple
        //                 accept={renderState.type.fields[props.fieldId].mimetypes.toString()}                
                
        //                 onChange={handleUploadFile} 
        //                 disabled={(renderState.form.locked || renderState.form.disabled)}
        //             />

        //             <Files 
        //                 {...props}
                                                
        //                 onGetPreview={handleGetPreview}
        //                 onDownloadFile={handleDownloadFile}
        //                 onRemoveFile={handleRemoveFile}              
        //                 onError={handleError}

        //                 meta={meta}
        //                 uploadProgress={uploadProgress}
        //             />   
        //         </React.Fragment>                
        //     )
        // }
        
        // case "image": {
        //     return (
        //         <React.Fragment>
        //             <input style={{ display: 'none' }}
        //                 id={`${props.fieldId}-file`}                
        //                 type="file" 
        //                 accept={props.type.fields[props.fieldId].mimetypes.toString()}
                
        //                 onChange={handleUploadFile} 
        //                 disabled={(renderState.form.locked || renderState.form.disabled)}
        //             />

        //             <Image
        //                 {...props}
                        
        //                 onGetPreview={handleGetPreview}
        //                 onDownloadFile={handleDownloadFile}
        //                 onRemoveFile={handleRemoveFile}              
        //                 onError={handleError}

        //                 meta={meta}
        //                 uploadProgress={uploadProgress}
        //             />

        //     </React.Fragment>
        //     )
        // }

        case "images": {
            return (
                <React.Fragment>
                    {/* <input style={{display: 'none'}} 
                        id={`${fieldId}-file`}
                        type="file"
                        multiple 
                        accept={renderState.type.fields[fieldId].mimetypes.toString()}
                        
                        onChange={handleUpload}                
                        disabled={(renderState.form.locked || renderState.form.disabled)}
                    /> */}
        
                    <Images 
                        {...props}

                        value={renderState.entity.data[fieldId]}
                        form={renderState.form}
                        
                        onGetPreview={handleGetPreview}
                        onDownloadFile={(storageId) => {API.storage.download(accessToken, renderState.entity.id, fieldId, storageId)}}
                        onRemoveFile={handleRemoveFile}              
                        onError={handleError}

                        meta={meta}
                        uploadProgress={uploadProgress}
                    />                
                </React.Fragment>
            )
        }

        default: {
            return (<React.Fragment/>)
        }
    }
}