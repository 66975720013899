// --------------------------------------------------------- REACT ------
import React, { memo, Fragment } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    Grid,
    Typography,
    Button
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
import { useRenderState } from './../../../components/context/renderStateProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Field from './field/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const SubComponentField = (props) => {
    const {parentType, element, entity, ...otherProps} = props
    const {renderState} = useRenderState()
    
    if (parentType === "typography" && renderState.type.fields[element.value].type !== "stringformat") {
        // Entity can be passed to Element, 
        // and if thats the case we use that 
        // to render instead of the current 
        // renderState Entity.
        // return (!entity ? (renderState.entity.data[element.value] || "") : entity.data[element.value] || "")

        // When inserting a Field into a Typograhy Element, 
        // the formatData will be used.
        // console.log (renderState.entity)



        return (renderState.entity.data[element.value] || "")
    } else {
        return (
            <Field                        
                fieldId={element.value}
                {...element.props}
                {...otherProps}                    
            />
        )
    }
}

const Component = (props) => {
    const{element, parentType, ...otherProps} = props

    // console.log ("Render.Element")    

    switch (element.type) {
        case "grid": {
            return (
                <Grid {...element.props}>
                    {element.content.map((element, index) => (
                        <Component
                            element={element}
                            parentType={"grid"}
                            {...otherProps}
                        />
                    ))}
                </Grid>
            )
        }

        case "typography": {            
            return (
                <Typography {...element.props}>
                    {element.content.map((element) => (
                        <Component 
                            element={element}
                            parentType={"typography"}
                            {...otherProps}                            
                        />
                    ))}
                </Typography>
            )
        }

        case "string": {
            return (
                element.value
            )
        }

        case "field": {
            return (
                <SubComponentField
                    parentType={parentType}
                    element={element}
                    {...otherProps}
                />
            )
            // if (parentType === "typography") {
            //     return (renderState.entity.data[element.value])
            // } else {
            //     return (
            //         <Field                        
            //             fieldId={element.value}
            //             {...element.props}
            //             {...otherProps}                    
            //         />
            //     )
            // }
        }

        case "button": {
            return (
                <Button {...element.props}>{element.label}</Button>
            )
        }

        default: {
            return (
                <React.Fragment/>
            )
        }
    }
}

export default memo(Component)