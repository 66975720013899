const API_URL = "/api/simplecrm/entities/"

class entities {    
    static get = async (accessToken, entityId) => {
        let fetchGet = await fetch(`${API_URL}${entityId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })           
    
        if (!fetchGet.ok)
            throw new Error((await fetchGet.json()).error.code)
    
        return await fetchGet.json()
    }

    static post = async (accessToken, typeId, data) => {
        let fetchPost = await fetch(`${API_URL}`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify({                        
                typeId: typeId,
                data: data,
            })
        })

        if (!fetchPost.ok)
            throw new Error(await fetchPost.text())
        
        return await fetchPost.json()
    }

    static patch = async (accessToken, entity) => {
        let fetchPatch = await fetch(`${API_URL}${entity.id}`, {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify({
                data: entity.data
            })
        })  
    
        if (!fetchPatch.ok)
            throw new Error(await fetchPatch.text())
    }

    static acquireLock = async (accessToken, entityId) => {
        let getFetch = await fetch(`${API_URL}${entityId}`, {
            method: 'LOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })  
                    
        if (getFetch.ok)
            return true
    
        return false
    }

    static releaseLock = async (accessToken, entityId) => {
        await fetch(`${API_URL}${entityId}`, {
            method: 'UNLOCK',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            }            
        })          
    }
}

export default entities