import { SimpleUICommonInterpolate } from './../../../../../simpleUI'
import { useRenderState } from './../../../../../components/context/renderStateProvider'

const Component = (props) => {
    const {fieldId, type, form} = props
    const {renderState} = useRenderState()

    // console.debug ("stringFormat > RENDER:"+ fieldId)
    
    const value = SimpleUICommonInterpolate(renderState.type.fields[fieldId].format, renderState.entity.data)
    return (value)
}

export default Component