import React, { useState, Fragment } from 'react'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material/'

import {
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'

import { renderStateMemo } from './../../../../../../../components/context/renderStateProvider'

import Render from './../../../../render'
import Element from './../../../../element'

const Item = (props) => {
    const { value, layout, onChange, onSubscribe, onPatch } = props

    // const renderTableHead = () => (
    //     <TableHead>
    //         <TableRow>
    //             {layout.build.columns.map((column, index) => (
    //                 column.headerName !== null && <TableCell key={index}>{column.headerName}</TableCell>
    //             ))}
    //         </TableRow>
    //     </TableHead>
    // )
    
    // const renderTableBodyWithColumns = () => (
    //     <TableBody>
    //         {formData.map((entity, index) => (
    //             <TableRow key={index}>
    //                 {layout.build.columns.map((column, colIndex) => (
    //                     <TableCell key={colIndex} component="th" scope="row">
    //                         {column.content.map((element, elemIndex) => (
    //                             <Element
    //                                 key={elemIndex}
    //                                 element={element}
    //                                 parentType="tablecell"
    //                                 entity={entity}
    //                             />
    //                         ))}
    //                     </TableCell>
    //                 ))}
    //             </TableRow>
    //         ))}
    //     </TableBody>
    // )

    // const renderTableBodyWithoutColumns = () => (
    //     <TableBody>
    //         {dataGridRows.map((entity, index) => (
    //             <TableRow key={index}>
    //                 <TableCell component="th" scope="row">
    //                     <Render
    //                         type={value.type}    
    //                         entity={value.entity}
            
    //                         onSubscribeCallback={(props) => onSubscribe({entityId: value.entity.id, payload: props})}
    //                         onChangeCallback={(props) => onChange({entityId: value.entity.id, payload: props})}

    //                         onPatchCallback={(props) => onPatch({entityId: value.entity.id, payload: props})}            
    //                     />
    //                 </TableCell>
    //             </TableRow>
    //         ))}
    //     </TableBody>
    // )
       
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <Render
                    type={value.type}    
                    entity={value.entity}
    
                    onSubscribeCallback={(props) => onSubscribe({entityId: value.entity.id, payload: props})}
                    onChangeCallback={(props) => onChange({entityId: value.entity.id, payload: props})}

                    onPatchCallback={(props) => onPatch({entityId: value.entity.id, payload: props})}            
                />
            </TableCell>
        </TableRow>

        // <Render
        //     type={value.type}    
        //     entity={value.entity}
            
        //     onSubscribeCallback={(props) => onSubscribe({entityId: value.entity.id, payload: props})}
        //     onChangeCallback={(props) => onChange({entityId: value.entity.id, payload: props})}

        //     onPatchCallback={(props) => onPatch({entityId: value.entity.id, payload: props})}            
        // >
        //     <Accordion 
        //         key={value.entity.id} 
        //         expanded={isAccordionOpen === `accordion-${value.entity.id}`} 
        //         onChange={toggleAccordion(`accordion-${value.entity.id}`)}
        //         slotProps={{ transition: { unmountOnExit: true } }}
        //     >
        //         <AccordionSummary
        //             expandIcon={<ArrowDownwardIcon />}
        //             aria-controls={`${value.entity.id}-content`}
        //             id={value.entity.id}
        //         >
        //             {layout.build.summary.content.map((element, layoutIndex) => (
        //                 <Element
        //                     key={layoutIndex}
        //                     element={element}
        //                     parentType={undefined}                            
        //                 />
        //             ))}
        //         </AccordionSummary>

        //         <AccordionDetails>
        //             <Fragment id="renderplaceholder"/>
        //         </AccordionDetails>
        //     </Accordion>
        // </Render>
    )
}

const Component = (props) => {
    const { layout, list, onChange, onSubscribe, onPatch } = props
   
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {list.map((item, index) => (
                        <Item 
                            key={index} 

                            value={item} 
                            layout={layout} 

                            onSubscribe={onSubscribe} 
                            onChange={onChange} 
                            onPatch={onPatch}                                         
                        />
                    ))}
                </TableBody>
                {/* {layout.build.columns ? renderTableHead() : null} */}
                {/* {layout.build.columns ? renderTableBodyWithColumns() : renderTableBodyWithoutColumns()} */}
            </Table>
        </TableContainer>


        //     {list.map((item, index) => (
        //         <Item 
        //             key={index} 

        //             value={item} 
        //             layout={layout} 

        //             onSubscribe={onSubscribe} 
        //             onChange={onChange} 
        //             onPatch={onPatch}                                         
        //         />
        //     ))}
        // </React.Fragment>
    )
}

export default renderStateMemo(Component)