import React from 'react'

import {
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material/'

import { renderStateMemo } from './../../../../../components/context/renderStateProvider'

const Component = (props) => {
    const {fieldId, value, onChange, type, form} = props
    // console.debug ("BOOLEAN > RENDER:"+ fieldId)

    return (
        <FormGroup>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={value}

                        required={type.required}
                        disabled={(form.locked || form.disabled)}

                        onChange={onChange}
                    />
                } 
                label={type.label}
            />
        </FormGroup>                
    )
}

export default renderStateMemo(Component)