const API_URL = "/api/simplecrm/entities/"

class storage {    
    static getMeta = async (accessToken, entityId, fieldId) => {
        let getMeta = await fetch(`${API_URL}${entityId}/storage/${fieldId}/meta/`, {
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })
    
        if (!getMeta.ok)
            throw new Error((await getMeta.json()).error.code)                
    
        return await getMeta.json()            
    }

    static getPreview = async (accessToken, entityId, fieldId, storageId) => {
        console.log ("getpreview")
        let preview = await fetch(`${API_URL}${entityId}/storage/${fieldId}/preview/${storageId}`, {
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })

        return URL.createObjectURL(await preview.blob())    
    }

    static upload = async (accessToken, entityId, fieldId, payload) => {
        let postStorage = await fetch(`${API_URL}${entityId}/storage/${fieldId}/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: payload
        })

        if (!postStorage.ok)
            throw new Error((await postStorage.json()).error.code)
    
        return await postStorage.json()        
    }

    static download = async (accessToken, entityId, fieldId, storageId) => {
        // if (!storageId)
        //     storageId = renderState.entity.data[fieldId]

        let getMeta = await fetch(`${API_URL}${entityId}/storage/${fieldId}/meta/${storageId}`, {
            method: 'GET',                
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })

        if (!getMeta.ok)
            throw new Error((await getMeta.json()).error.code)

        let meta = await getMeta.json()

        const fetchGet = await fetch(`${API_URL}${entityId}/storage/${fieldId}/download/${storageId}`, {
            method: 'GET',            
            headers: {
                'Authorization': "Bearer "+ accessToken
            }
        })

        const blob = await fetchGet.result()
        const url = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url          
        a.download = meta.originalname
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)        
    }
}

export default storage
