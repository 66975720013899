import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

// MUI
// import { createTheme, ThemeProvider } from '@mui/material/styles'

import { createTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';


import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
// import CircularProgress from '@mui/material/CircularProgress'

// Components
import {
        SimpleUIAppBar, 
        SimpleUISideBarNav
} from './simpleUI'

// import { AutoFixHigh, Public } from "@mui/icons-material"

const defaultTheme = createTheme({
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            }
        },
        
        MuiGrid2: {
            defaultProps: {
                // xl: 12,
                // lg: 12,
                // md: 12,
                // sm: 12,
                // xs: 12
            }
        }
    
    }
})


const theme = createTheme({
    colorPreset: 'blue',
    direction: "ltr",
    paletteMode: "dark",
    contrast: 'high'
  });

function ProtectedLayout(props) {
    const { navigation, settings } = props

    const [nav, setNavigation] = useState([])

    useEffect(() => {                 
        if (!navigation)
            return

        setNavigation(navigation)
    }, [navigation])

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                {!settings.hideAppBar && <SimpleUIAppBar settings={settings}/>}
                {!settings.hideNavBar && <SimpleUISideBarNav navigation={nav} settings={settings}/>}

                <Box component="main"
                    sx={{
                        backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        marginTop: settings.hideAppBar ? '0px' : '64px',
                        height: settings.hideAppBar ? '100vh' : 'calc(100vh - 64px)',
                        width: '100%',
                        overflow: 'auto',
                    }}>                    
                    {/* <Container disableGutters maxWidth="false" sx={{ 
                        // mt: 4, 
                        // mb: 4, 
                        height: settings.hideAppBar ? '100vh' : 'calc(100vh - 64px)'
                    }}> */}
                        <Outlet />
                    {/* </Container> */}
                </Box>                
            </Box>
        </ThemeProvider>
    )
}

function PublicLayout(props) {
    return (
        <ThemeProvider theme={defaultTheme}>            
                <Outlet />            
        </ThemeProvider>
    )
}

export {
    PublicLayout,
    ProtectedLayout,    
}