import React, { useState, Fragment } from 'react'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material/'

import {
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'

import { renderStateMemo } from './../../../../../../../components/context/renderStateProvider'

import Render from './../../../../render'
import Element from './../../../../element'

const Item = (props) => {
    const { value, layout, onChange, onSubscribe, onPatch, toggleAccordion, isAccordionOpen } = props

    console.log ("red"+ value.entity.id)
       
    return (
        <Render
            type={value.type}    
            entity={value.entity}
            
            onSubscribeCallback={(props) => onSubscribe({entityId: value.entity.id, payload: props})}
            onChangeCallback={(props) => onChange({entityId: value.entity.id, payload: props})}

            onPatchCallback={(props) => onPatch({entityId: value.entity.id, payload: props})}            
        >
            <Accordion 
                key={value.entity.id} 
                expanded={isAccordionOpen === `accordion-${value.entity.id}`} 
                onChange={toggleAccordion(`accordion-${value.entity.id}`)}
                slotProps={{ transition: { unmountOnExit: true } }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls={`${value.entity.id}-content`}
                    id={value.entity.id}
                >
                    {layout.build.summary.content.map((element, layoutIndex) => (
                        <Element
                            key={layoutIndex}
                            element={element}
                            parentType={undefined}                            
                        />
                    ))}
                </AccordionSummary>

                <AccordionDetails>
                    <Fragment id="renderplaceholder"/>
                </AccordionDetails>
            </Accordion>
        </Render>
    )
}

const Component = (props) => {
    const { layout, list, onChange, onSubscribe, onPatch } = props

    const [isAccordionOpen, setIsAccordionOpen] = useState("")
    const toggleAccordion = (accordion) => (event, newValue) => {
        setIsAccordionOpen(newValue ? accordion : false)
    }
 
    return (
        <React.Fragment>
            {list.map((item, index) => (
                <Item 
                    key={index} 

                    value={item} 
                    layout={layout} 

                    onSubscribe={onSubscribe} 
                    onChange={onChange} 
                    onPatch={onPatch} 
                    
                    toggleAccordion={toggleAccordion} 
                    isAccordionOpen={isAccordionOpen}
                />
            ))}
        </React.Fragment>
    )
}

export default renderStateMemo(Component)