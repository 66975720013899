import React, { useState, useEffect } from 'react'

import {
    Dialog,
} from '@mui/material/'

const Component = (props) => {   
    const { state, setState } = props 
    const [ children, setChildren ] = useState(null)

    useEffect(() => {
        // Only set children once.
        if (state.children && children === null)
            setChildren(React.cloneElement(state.children))
                
    },[state])    

    const handleClose = (event, reason) => {
        setState({ ...state, open: false })
    }
    
    return (
        <Dialog 
            open={state.open}

            fullWidth
            keepMounted={true}            
            
            onClose={handleClose}            
        >                        
            {children}
        </Dialog>
    )
}

export default Component